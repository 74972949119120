import {
  get, post, excel
} from '../utils/http';

// 获取批次
export function GetPageList(data) {
  return get('xsz/SY_XSZ_CCQJ/GetPageList/', data);
}

// 获取省份
export function GetSF(data) {
  return get('xsz/SY_XSZ_ZTGL/GetSF/', data);
}

// 获取市
export function GetSZS(data) {
  return get('xsz/SY_XSZ_ZTGL/GetSZS/', data);
}

// 乘车省
export function GetCCSF(data) {
  return get('xsz/SY_XSZ_ZTGL/GetCCSF/', data);
}

// 乘车站点
export function GetCCZD(data) {
  return get('xsz/SY_XSZ_ZTGL/GetCCZD/', data);
}

// 表单数据
export function GetCCQJFormData(data) {
  return get('xsz/SY_XSZ_CCQJ/GetFormData/', data);
}

// 保存数据
export function HTSave(data) {
  return post('xsz/SY_XSZ_CCQJ/HTSave/', data);
}

// 获取批次
export function StuGetList(data) {
  return get('xsz/SY_XSZ_XSZBB/StuGetList/', data);
}

// 保存数据
export function XSZBBSave(data) {
  return post('xsz/SY_XSZ_XSZBB/Save/', data);
}

// 获取申请信息
export function StuGetBBFormData(data) {
  return get('xsz/SY_XSZ_XSZBB/GetFormData/', data);
}

// 获取待审批
export function GetSPPageList(data) {
  return get('xsz/SY_XSZ_XSZBB/GetSPPageList/', data);
}

// 获取申请信息
export function GetDetail(data) {
  return get('xsz/SY_XSZ_XSZBB/GetDetail/', data);
}

// 审批
export function SaveSP(data) {
  return post('xsz/SY_XSZ_XSZBB/SaveSP/', data);
}

// 获取批次
export function GetWTXPageList(data) {
  return get('xsz/SY_XSZ_CCQJ/GetWTXPageList/', data);
}

// 获取导出数据
export function exportSearchExcel(data) {
  return excel('xsz/SY_XSZ_CCQJ/ExportExcel/', data);
}
